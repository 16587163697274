import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
    position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};
